import {removeLeadingZero} from './vendor/utils.js'

const TIMEOUT_MS = 10000; // 10 seconds to redirect
const PHONE_NUMBER = '02323232323';
const WEBSITE_URL = 'https://www.worcester-bosch.co.uk';

(async function redirect() {

  const timeoutHandler = setTimeout(() => {
    displayError();
  }, TIMEOUT_MS)
  try {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');

    const legacyData = await fetch(`${process.env.LEGACY_URL}?token=${token}&contactCentre=false`);
    const legacyJson = await legacyData.json();
    const apptNumber = removeLeadingZero(legacyJson.activity.apptNumber);
    const result = await fetch(process.env.LOOKUP_URL.replace('{apptNumber}',apptNumber) , {
      headers: {
        'x-api-key': '5352b60a-68aa-4c52-91c3-f5001e52fad6'
      }
    });
    const json = await result.json();
    window.location.replace(json.home);
  } catch (err){
    displayError();
  } finally {
    clearTimeout(timeoutHandler);
  }
})(); // Use IIFE to defined and run the function immediately

function displayError(){
  document.getElementById('error').innerHTML = `We're very sorry but we didn't manage to redirect you to our new Appointment Assistant, please call Worcester Bosch on <a href="tel:${PHONE_NUMBER}">${PHONE_NUMBER}</a>, or via Live Chat on our <a href="${WEBSITE_URL}" target="_blank">website</a>`;
  document.getElementById('prompt').hidden = true;
}
